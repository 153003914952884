import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../index.reducers";
import { handleMobileAppNavigation } from "../../utils/navigator-util";
import { AccountSettingsModal } from '../account-settings-modal/account-settings-modal';
import { Badges } from "../badges/badges";
import { QRCodeModal } from '../qrcode-modal/qrcode-modal';
import './account-page-header.scss';

interface Props {
    size: number;
}
export const AccountPageHeader = (props: Props) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const loginToken = params['loginToken'];

    const { accountDetail, rounds } = useSelector((state: RootState) => state.account);

    const [showQRModal, setShowQRModal] = useState(false);
    const [showAccountSettingsModal, setShowAccountSettingsModal] = useState(!!loginToken);

    if (!accountDetail) {
        return null;
    }

    const handleQRModalHide = () => {
        setShowQRModal(false);
    };

    const handleAccountSettingsModalHide = () => {
        setShowAccountSettingsModal(false);
    };

    const launcherId = accountDetail.launcherId && (accountDetail.launcherId.indexOf('0x') === 0 ? accountDetail.launcherId : `0x${accountDetail.launcherId}`);
    let hasOldClient = true;
    try {
        hasOldClient = !accountDetail.clientVersion || accountDetail.clientVersion < "2.4";
    } catch (e) {
        console.log("compareVersions", e);
    }

    return (
        <>
            {/* {hasOldClient && accountDetail.launcherId &&
                <div style={{ color: 'red', fontWeight: 'bold', fontSize: 'larger' }}>This account will not be receiving some or all of its rewards due to farming on at least one outdated Chia client, <a style={{ paddingTop: '4px' }} target="_blank" onClick={(ev: any) => handleMobileAppNavigation(ev)} href="https://docs.chia.net/consensus-forks/">learn more</a></div>
            } */}
            <div className='account-page-header'>
                <div className='title-container' style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div>
                        <h4 className='title'>{accountDetail.accountDisplayName}{accountDetail.accountDisplayName ? <>&nbsp;</> : <></>}<Badges size={props.size} tenureDate={accountDetail.tenureDateTimeUtc} rank={rounds?.length ? rounds[0].roundRank : accountDetail.rank} badges={accountDetail.badges}></Badges></h4>
                        <div className='subtitle'>{launcherId}</div>
                    </div>
                    <div className='login-link' style={{ marginTop: '30px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><title>Key</title><path d="M218.1 167.17c0 13 0 25.6 4.1 37.4-43.1 50.6-156.9 184.3-167.5 194.5a20.17 20.17 0 00-6.7 15c0 8.5 5.2 16.7 9.6 21.3 6.6 6.9 34.8 33 40 28 15.4-15 18.5-19 24.8-25.2 9.5-9.3-1-28.3 2.3-36s6.8-9.2 12.5-10.4 15.8 2.9 23.7 3c8.3.1 12.8-3.4 19-9.2 5-4.6 8.6-8.9 8.7-15.6.2-9-12.8-20.9-3.1-30.4s23.7 6.2 34 5 22.8-15.5 24.1-21.6-11.7-21.8-9.7-30.7c.7-3 6.8-10 11.4-11s25 6.9 29.6 5.9c5.6-1.2 12.1-7.1 17.4-10.4 15.5 6.7 29.6 9.4 47.7 9.4 68.5 0 124-53.4 124-119.2S408.5 48 340 48s-121.9 53.37-121.9 119.17zM400 144a32 32 0 11-32-32 32 32 0 0132 32z" fill="none" stroke="#03b000" stroke-linejoin="round" stroke-width="32" /></svg>
                        &nbsp;<a target="_blank" onClick={(ev: any) => handleMobileAppNavigation(ev)} href="https://blog.pool.space/how-to-login-to-your-space-pool-farm-settings-page-a161a6bddb91">Login</a>
                    </div>
                </div>

                <QRCodeModal show={showQRModal} launcherId={accountDetail.launcherId} onHide={handleQRModalHide} />
                {loginToken ? <AccountSettingsModal
                    alias={accountDetail.accountDisplayName}
                    loginToken={loginToken}
                    show={showAccountSettingsModal}
                    joinDate={accountDetail.joinDateTimeUtc}
                    launcherId={accountDetail.launcherId}
                    onHide={handleAccountSettingsModalHide}
                /> : null}
            </div >
        </>
    );
};